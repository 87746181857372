<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">取餐码</div>
      <div class="nav_left_btn" @click="toOrder">
        <img :src="cancel" alt width="15" height="25" />
      </div>
      <!--      <div class="nav_right_btn">下一页</div>-->
    </div>
    <div class="content">
      <img :src="generateQR" alt style="width: 90%" />
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import QRCode from "qrcode";
import { getQR } from "@/api/order";
import { Toast } from "vant";

export default {
  name: "MealCode",
  data() {
    return {
      show: false,
      generateQR: "",
      cancel,
    };
  },
  created() {
    const { id } = this.$route.params;
    this.fetch(id);
  },
  methods: {
    async fetch(id) {
      const { code, data, message } = await getQR(id);
      if (code === 0) {
        this.showQR = true;
        this.show = true;
        this.generateQR = await QRCode.toDataURL(data);
        this.show = false;
      } else {
        Toast.fail({
          message,
          duration: 1000,
        });
      }
    },
    toOrder() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    //position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
